import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login/Login.vue'//登录页
import RealTime from '../views/RealTime/RealTime.vue'//实时监控
import History from '../views/History/History.vue'//历史记录
import AlarmLog from '../views/AlarmLog/AlarmLog.vue'// 报警记录
import User from '../views/User/User.vue'// 用户中心
import UserDetail from '../views/User/UserDetail.vue'// 用户详情
import NickNmae from '../views/User/NickName.vue'// 用户名修改
import Password from '../views/User/Password.vue'// 密码修改
import List from '../views/User/List.vue'// 用户管理
import Show from '../views/User/Show.vue'// 用户信息详情
import Version from '../views/User/Version.vue'// 版本信息
import no from '../views/404.vue'//消息

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [
	{
		path: '/',
		name: 'RealTime',
		component: RealTime
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/history',
		name: 'History',
		component: History
	},
	{
		path: '/alarmLog',
		name: 'AlarmLog',
		component: AlarmLog
	},
	{
		path: '/user',
		name: 'User',
		component: User
	},
	{
		path: '/user/detail',
		name: 'UserDetail',
		component: UserDetail
	},
	{
		path: '/user/update/nickname',
		name: 'NickNmae',
		component: NickNmae
	},
	{
		path: '/user/update/password',
		name: 'Password',
		component: Password
	},
	{
		path: '/user/list',
		name: 'List',
		component: List
	},
	{
		path: '/user/show',
		name: 'Show',
		component: Show
	},
	{
		path: '/user/version',
		name: 'Version',
		component: Version
	},
	{
		path: '/404',
		name: 'no',
		component: no
	},
]

const router = new VueRouter({
	routes
})

export default router

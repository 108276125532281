import { queryHistory } from '@/api/history'

const history = {
    state: {
        historyList: {},
    },

    mutations: {
        // 获取历史数据
        SET_HISTORYLIST: (state, historyList) => {
            state.historyList = historyList
        },
    },

    actions: {
        // 获取历史数据
        getHistoryList({ commit }, params) {
            return new Promise((resolve, reject) => {
                queryHistory(params).then(res => {
                    commit('SET_HISTORYLIST', res && res.msg ? res.msg : {})
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}

export default history

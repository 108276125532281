import { queryToken, queryWhoami, checkPassword, changePassword, queryUserList, queryUserDetail, createUser, updateUser, deleteUser } from '@/api/user'

const user = {
    state: {
        token: '',
        whoami: {},
        userList: [],
        userDetail: {},
    },

    mutations: {
        // 获取token
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        // 获取当前用户信息
        SET_WHOAMI: (state, whoami) => {
            state.whoami = whoami
        },
        // 获取用户列表
        SET_USERLIST: (state, userList) => {
            state.userList = userList
        },
        // 获取用户信息
        SET_USERDETAIL: (state, userDetail) => {
            state.userDetail = userDetail
        },
    },

    actions: {
        // 登录
        login({ dispatch, commit }, params) {
            return new Promise((resolve, reject) => {
                queryToken(params).then(res => {
                    commit('SET_TOKEN', res.msg)
                    if (res && res.msg && res.msg !== '') {
                        sessionStorage.setItem('token', res.msg) //保存token
                        window.location.href = '/'
                    }
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 退出登录
        logOut({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('SET_TOKEN', '')
                sessionStorage.removeItem('token')
                window.location.href = '/#/login'
            })
        },
        // 获取当前用户信息
        getWhoami({ commit }, params) {
            return new Promise((resolve, reject) => {
                queryWhoami().then(res => {
                    commit('SET_WHOAMI', res.msg ? res.msg : {})
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 验证密码
        checkPasswordInfo({ commit }, params) {
            return new Promise((resolve, reject) => {
                checkPassword(params).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 修改密码
        changePasswordInfo({ commit }, params) {
            return new Promise((resolve, reject) => {
                changePassword(params).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 获取用户列表
        getUserList({ commit }, params) {
            return new Promise((resolve, reject) => {
                queryUserList(params).then(res => {
                    commit('SET_USERLIST', res.msg ? res.msg : [])
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 获取用户详情信息
        getUserDetail({ commit }, params) {
            return new Promise((resolve, reject) => {
                queryUserDetail(params).then(res => {
                    commit('SET_USERDETAIL', res.msg ? res.msg : {})
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 创建用户
        createUserInfo({ commit }, params) {
            return new Promise((resolve, reject) => {
                createUser(params).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 修改用户
        updateUserInfo({ commit }, params) {
            return new Promise((resolve, reject) => {
                updateUser(params).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 删除用户
        deleteUserInfo({ commit }, params) {
            return new Promise((resolve, reject) => {
                deleteUser(params).then(res => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}

export default user

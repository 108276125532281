<template>
  <div class="alarmLogListBox">
    <vue-loadmore :on-loadmore="onLoad" :finished="finished" v-if="list.length">
      <div v-for="(item, i) of list" :key="i">
        <el-card class="cardBox">
          <div slot="header" class="clearfix">
            <span>{{ item.sid || "" }}</span>
            <span>{{ item.time }}</span>
          </div>
          <div
            class="decviceContentBox"
            v-for="(itemType, i) in typeList"
            :key="i"
          >
            <div class="alarmIconBox">
              <van-icon
                size="2vh"
                class-prefix="my-icon"
                :name="itemType.type === 'type' ? 'baojing' : 'miaoshu'"
                class="iconBox"
              />
              <span class="textBox">{{ itemType.name }}</span>
            </div>
            <div class="realValueGroupBox">
              <span v-if="itemType.type === 'type'">
                {{ item.alarmTypeName }}</span
              >
              <van-tag
                v-else-if="item.status === '报警' || item.status === '复位'"
                size="large"
                :type="item.status === '报警' ? 'danger' : 'primary'"
                >{{ item.status }}</van-tag
              >
            </div>
          </div>
        </el-card>
      </div>
    </vue-loadmore>
    <div v-else>
      <NoDataBox />
    </div>
  </div>
</template>
<script>
import { hasObjArr, isNullOrUndefined, getAlarmTypeName } from "@/utils/index";
import { mapGetters } from "vuex";
import moment from "moment";
import NoDataBox from "../Common/NoData.vue";
export default {
  name: "AlarmLogList",
  props: ["onLoad", "finished", "filterParams"],
  components: {
    NoDataBox,
  },
  computed: {
    ...mapGetters(["alarmLogList"]),
  },
  watch: {
    // 重置数据
    filterParams(val) {
      this.list = [];
    },
    alarmLogList(val) {
      let list = [...this.list];
      // 处理数据
      let alarmLogData = val && hasObjArr(val.data) ? val.data : []; //报警记录
      // 删除不是状态记录的值
      let changeAlarmLogData = [];
      hasObjArr(alarmLogData)
        ? alarmLogData.map((v) => {
            v.sid.includes("/alert") ? changeAlarmLogData.push(v) : null;
          })
        : [];
      // 处理报警记录
      hasObjArr(changeAlarmLogData)
        ? changeAlarmLogData.map((v) => {
            let obj = { ...v };
            obj.key = v.id || "-1";
            obj.time = v.created_at
              ? moment(v.created_at).format("YYYY-MM-DD HH:mm:ss")
              : "";
            obj.alarmTypeName = isNullOrUndefined(v.pid)
              ? getAlarmTypeName(v.pid)
              : "";
            obj.status = v.v === "0" ? "报警" : v.v === "1" ? "复位" : "";
            hasObjArr(obj) ? list.push(obj) : null;
          })
        : null;
      //设置报警记录
      this.list = list;
    },
  },

  data() {
    return {
      list: [],
      typeList: [
        {
          name: "事件",
          type: "type",
        },
        {
          name: "状态",
          type: "status",
        },
      ],
    };
  },
  methods: {},
};
</script>  
<style lang="scss">
@import "./alarmLog.scss";
</style>
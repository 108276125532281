<template>
  <div class="subjectBox">
    <div class="backBox" @click="goBack">
      <van-icon v-if="this.isBack" size="3vh" name="arrow-left" />
    </div>
    <div class="titleBox">
      {{ this.titleText }}
    </div>
    <div class="filterBox">
      <van-icon
        v-if="this.isFilter"
        size="3vh"
        name="ellipsis"
        @click="showFilter"
      />
      <span class="submitBox" v-if="this.isSubmit" @click="this.submit"
        >确定</span
      >
    </div>
    <van-popup
      v-model="popupShow"
      position="right"
      :style="{ width: '70%', height: '100%', padding: '1vh 0' }"
    >
      <van-form
        class="filterFormBox"
        v-if="this.filterList && this.filterList.length && this.modelData"
        validate-first
        @submit="this.confirmFilter"
      >
        <div v-for="(item, i) in this.filterList" :key="i">
          <van-field
            v-if="item.type === 'input'"
            :label="item.label"
            :label-width="item.labelWidth"
            :name="item.name"
            :clearable="item.clearable"
            v-model="modelDataForm[item.name]"
          />
          <van-field
            v-if="item.type === 'picker'"
            readonly
            clickable
            :name="item.name"
            v-model="modelDataForm[item.name]"
            :label="item.label"
            :label-width="item.labelWidth"
            @click="modelDataForm[`${item.name}` + 'ShowPicker'] = true"
          />
          <van-popup
            v-model="modelDataForm[`${item.name}` + 'ShowPicker']"
            position="bottom"
          >
            <van-picker
              :default-index="modelDataForm[`${item.name}` + 'DefaultPicker']"
              :title="item.columsTitle"
              show-toolbar
              :columns="item.columns"
              @confirm="
                (val) => {
                  onPickerConfirm(val, item.name);
                }
              "
              @cancel="modelDataForm[`${item.name}` + 'ShowPicker'] = false"
            />
          </van-popup>
          <van-field
            v-if="item.type === 'dateTime'"
            readonly
            clickable
            :name="item.name"
            v-model="modelDataForm[item.name]"
            :label="item.label"
            :label-width="item.labelWidth"
            @click="modelDataForm[`${item.name}` + 'DateTime'] = true"
          />
          <van-popup
            v-model="modelDataForm[`${item.name}` + 'DateTime']"
            position="bottom"
          >
            <van-datetime-picker
              :type="item.dateTimerPicker"
              @confirm="
                (val) => {
                  onDateTimeConfirm(val, item.name, item.dateTimerPicker);
                }
              "
              @cancel="modelDataForm[`${item.name}` + 'DateTime'] = false"
            />
          </van-popup>
        </div>
        <div class="buttonAllBox">
          <van-button class="buttonBox" block type="info" native-type="submit">
            确定
          </van-button>
          <van-button
            class="buttonBox"
            block
            native-type="button"
            @click="showFilter"
          >
            取消
          </van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: [
    "titleText",
    "filterList",
    "modelData",
    "isFilter",
    "isBack",
    "backUrl",
    "isSubmit",
    "submit",
    "submitFilter",
  ],
  data() {
    return {
      popupShow: false,
      modelDataForm: this.modelData,
    };
  },

  methods: {
    // 返回上一页
    goBack() {
      this.backUrl ? this.$router.push(this.backUrl) : this.$router.back();
    },
    // 展开筛选
    showFilter() {
      this.popupShow = !this.popupShow;
    },
    // 确定筛选
    confirmFilter(val) {
      this.submitFilter(val);
      this.showFilter();
    },
    // 确定picker选择
    onPickerConfirm(value, params) {
      this.modelDataForm[params] = value;
      // 关闭筛选
      this.modelDataForm[`${params}` + "ShowPicker"] =
        !this.modelDataForm[`${params}` + "ShowPicker"];
    },
    // 确定dateTime选择
    onDateTimeConfirm(value, params, dateType) {
      let date = value;
      switch (dateType) {
        case "datetime":
          date = value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
          break;
        case "date":
          date = value ? moment(value).format("YYYY-MM-DD") : "";
          break;
      }
      this.modelDataForm[params] = date;
      // 关闭筛选
      this.modelDataForm[`${params}` + "DateTime"] =
        !this.modelDataForm[`${params}` + "DateTime"];
    },
  },
};
</script>
<style lang="scss">
$themeColor: #00a0e9;
$textColor: #00a0e9;
.subjectBox {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: $themeColor;
  text-align: center;
  font-size: 6vw;
  color: #fff;
  width: 100%;
  height: 4vh;
  padding: 2vh 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .titleBox {
    font-size: 3vh;
  }
  .backBox {
    display: flex;
    padding-left: 1vw;
  }
  .filterBox {
    display: flex;
    padding-right: 1vw;
    .submitBox {
      font-size: 3vh;
    }
  }
}
.filterFormBox {
  padding-top: 10px;
  .van-cell {
    padding: 10px;
  }
  .van-cell::after {
    content: none;
  }
  .van-overlay {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .van-picker__title {
    color: rgb(135, 135, 135);
  }
  .van-picker__cancel,
  .van-picker__confirm {
    color: $themeColor;
  }
  .van-field__control {
    padding: 0 1vw;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
  }
  .buttonAllBox {
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    .buttonBox {
      width: 30vw;
      max-width: 120px;
      border-radius: 5px;
      margin: 0 1vw;
      &:nth-of-type(1) {
        background-color: $themeColor;
      }
    }
  }
}
</style>

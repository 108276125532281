<template>
  <div class="alarmLogBox">
    <TitleBox
      :titleText="title"
      :isFilter="true"
      :filterList="filterList"
      :modelData="modelData"
      :submitFilter="this.submitFilter"
    />
    <div class="contentBox">
      <AlarmLogListBox
        :onLoad="this.onLoad"
        :finished="finished"
        :filterParams="filterParams"
      />
    </div>
    <Nav />
  </div>
</template>
<script>
import Nav from "../../components/Common/Nav.vue";
import TitleBox from "../../components/Common/Subject.vue";
import AlarmLogListBox from "../../components/AlarmLog/AlarmLog.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import {
  isGetToken,
  hasObjArr,
  isNullOrUndefined,
  getAlarmTypeValue,
} from "@/utils/index";

export default {
  name: "AlarmLog",
  components: {
    Nav,
    TitleBox,
    AlarmLogListBox,
  },
  computed: {
    ...mapGetters(["alarmLogList"]),
  },
  data() {
    return {
      title: "状态记录",
      filterList: [],
      modelData: [],
      filterParams: {},
      page: 1,
      page_size: 10,
      finished: false,
    };
  },
  created() {
    sessionStorage.setItem("is_alert", "false");
    this.$store.dispatch("setAlert", { isAlert: "false" });
    // 是否有token
    isGetToken(this);
    // 筛选的数据
    let filterList = [
      {
        label: "起始时间",
        name: "start_date",
        labelWidth: "4em",
        type: "dateTime",
        dateTimerPicker: "date",
      },
      {
        label: "终止时间",
        name: "end_date",
        labelWidth: "4em",
        type: "dateTime",
        dateTimerPicker: "date",
      },
      {
        label: "事件",
        name: "pid",
        labelWidth: "4em",
        type: "picker",
        columsTitle: "选择报警类型",
        columns: [
          "全部",
          "泵电机故障",
          "CB1 急停",
          "1#位移传感器故障",
          "1#压力传感器故障",
          "1#载荷超限报警",
          "1#同步工作故障",
          "2#位移传感器故障",
          "2#压力传感器故障",
          "2#载荷超限报警",
          "2#同步工作故障",
          "3#位移传感器故障",
          "3#压力传感器故障",
          "3#载荷超限报警",
          "3#同步工作故障",
          "4#位移传感器故障",
          "4#压力传感器故障",
          "4#载荷超限报警",
          "4#同步工作故障",
        ],
      },
    ];
    // model数据
    let modelData = {
      // 参数
      start_date: "",
      end_date: "",
      pid: "全部",
      // pciker select
      start_dateDateTime: false,
      end_dateDateTime: false,
      pidShowPicker: false,
      pidDefaultPicker: 0,
    };
    this.filterList = filterList;
    this.modelData = modelData;
    this.fetchAlarmLogList();
  },
  methods: {
    // 确定筛选
    submitFilter(data) {
      // 处理表单参数
      let changeData = {};
      data && data.start_date && data.start_date !== ""
        ? (changeData.start_date = moment(data.start_date).format(
            "YYYY-MM-DD 00:00:00"
          ))
        : null;
      data && data.end_date && data.end_date !== ""
        ? (changeData.end_date = moment(data.end_date).format(
            "YYYY-MM-DD 23:59:59"
          ))
        : null;
      // 指标
      data && data.pid && data.pid !== "全部"
        ? (changeData.pid = getAlarmTypeValue(data.pid))
        : null;
      this.filterParams = changeData;
      this.page = 1;
      this.fetchAlarmLogList(changeData);
    },
    // 请求报警记录记录
    fetchAlarmLogList(data) {
      // 处理参数
      let params = {};
      params.page = this.page;
      params.page_size = this.page_size;
      this.filterList.map((v) => {
        hasObjArr(data) && isNullOrUndefined(data[v.name])
          ? (params[v.name] = data[v.name])
          : null;
      });
      this.$store.dispatch("getAlarmLogList", params);
    },
    // 加载数据
    onLoad(done) {
      // 当前页数
      let pageNow = this.alarmLogList.current_page
        ? this.alarmLogList.current_page
        : 1;
      // 总页数
      let totalPage = this.alarmLogList.total_page
        ? this.alarmLogList.total_page
        : 1;
      if (pageNow < totalPage) {
        this.page++;
        this.fetchAlarmLogList(this.filterParams);
      } else {
        // all data loaded
        this.finished = true;
      }
      done();
    },
  },
};
</script>
<style lang="scss">
@import "./alarmLog.scss";
</style>

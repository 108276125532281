<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import moment from "moment";
import {
  hasObjArr,
  isNullOrUndefined,
  getAlarmTypeName,
  changeJSONStr,
} from "@/utils/index";
let timerAlarm = null;
export default {
  name: "App",
  data() {
    return {
      timerAlarm: null,
    };
  },
  created() {
    if (this.$route.path !== "/login") {
      this.fetchAlarmStatusList(); // 请求设备状态数据
    }
  },

  methods: {
    // 请求设备状态数据
    fetchAlarmStatusList() {
      if (this.$route.path === "/login") {
        timerAlarm ? clearTimeout(timerAlarm) : null; //清除定时器
        return;
      }
      // 已存在报警设备提示
      let alarmDeviceList = sessionStorage.getItem("alarmDevice")
        ? JSON.parse(sessionStorage.getItem("alarmDevice"))
        : [];
      timerAlarm ? clearTimeout(timerAlarm) : null; //清除定时器
      this.laodingAlarm = true;
      this.$store
        .dispatch("getAlarmStatusList")
        .then((res) => {
          let deviceList = res && hasObjArr(res.msg) ? res.msg : []; //设备列表
          if (hasObjArr(deviceList)) {
            for (let i in deviceList) {
              // 设备状态
              let deviceStatus = deviceList[i].v === "1" ? true : false;
              // 设备报警时间
              let deviceTime = deviceList[i].created_at
                ? moment(deviceList[i].created_at).format("YYYY-MM-DD HH:mm:ss")
                : "";
              // 设备报警类型
              let deviceType = isNullOrUndefined(deviceList[i].pid)
                ? getAlarmTypeName(deviceList[i].pid)
                : "";
              // 是否存在数据
              let isShowAlarm = false;
              if (alarmDeviceList.includes(deviceList[i].pid)) {
                // 删除复位的报警类型
                deviceList[i].v === "0"
                  ? (alarmDeviceList = alarmDeviceList.filter(
                      ({ v }) => v !== deviceList[i].pid
                    ))
                  : null;
              } else {
                isShowAlarm = true;
                deviceList[i].pid
                  ? alarmDeviceList.push(deviceList[i].pid)
                  : nul;
              }
              // 是否展示报警提示
              deviceStatus && isShowAlarm
                ? (this.timer = window.setTimeout(() => {
                    this.$notify({
                      title: `${deviceTime}`,
                      message: `${
                        (deviceList[i].sid || "") + "的" + deviceType
                      }`,
                      type: "warning",
                      duration: 1000 * 3,
                      offset: 80,
                      onClick: () => {
                        this.$notify.close();
                        this.$router.push("/alarmlog");
                      },
                    });
                  }, 0))
                : null;
            }
            if (hasObjArr(alarmDeviceList)) {
              // 设置报警信息
              if (this.$route.path !== "/alarmlog") {
                sessionStorage.setItem("is_alert", "true");
                this.$store.dispatch("setAlert", { isAlert: "true" });
              }
              // 缓存报警信息
              sessionStorage.setItem(
                "alarmDevice",
                changeJSONStr(alarmDeviceList)
              );
            } else {
              // 设置报警信息
              this.$store.dispatch("setAlert", { isAlert: "false" });
            }
          }
          this.laodingAlarm = false;
          timerAlarm = setTimeout(() => {
            this.fetchAlarmStatusList(); // 请求设备状态数据
          }, 1000 * 2);
        })
        .catch((error) => {
          this.laodingAlarm = false;
          timerAlarm = setTimeout(() => {
            this.fetchAlarmStatusList(); // 请求设备状态数据
          }, 1000 * 30);
        });
    },
  },
};
</script>

<style lang="scss">
</style>

import request from '@/utils/request'

// Get Token
export function queryToken(data) {
    return request({
        url: '/login/',
        method: 'post',
        data: data
    })
}

// Get Whoami
export function queryWhoami(data) {
    return request({
        url: '/whoami/',
        method: 'get',
        params: data
    })
}

// Chcek Password
export function checkPassword(data) {
    return request({
        url: 'user/check_password/',
        method: 'post',
        data: data
    })
}

// Change Password
export function changePassword(data) {
    return request({
        url: `/user/password/`,
        method: 'put',
        data: data
    })
}

// Get User List
export function queryUserList(data) {
    return request({
        url: '/user/',
        method: 'get',
        params: data
    })
}
// Get User Info
export function queryUserDetail(data) {
    return request({
        url: '/user/detail/',
        method: 'get',
        params: data
    })
}

// Create User
export function createUser(data) {
    return request({
        url: '/user/',
        method: 'post',
        data: data
    })
}

// Update User
export function updateUser(data) {
    return request({
        url: '/user/',
        method: 'put',
        data: data
    })
}

// Delete User 
export function deleteUser(data) {
    return request({
        url: `/user/`,
        method: 'delete',
        params: data
    })
}
<template>
  <div class="nickNameBox">
    <TitleBox
      :titleText="title"
      :isBack="true"
      :isSubmit="true"
      :submit="updateNickName"
    />
    <div class="contentBox">
      <van-form validate-first>
        <!-- 账号 -->
        <van-field
          label="用户名"
          label-width="3em"
          left-icon="user-o"
          name="name"
          v-model="nickName"
          :placeholder="rulesMsg.name"
          :rules="[{ required: true, message: rulesError.name }]"
        />
      </van-form>
    </div>
    <Nav />
  </div>
</template>
<script>
import Nav from "../../components/Common/Nav.vue";
import TitleBox from "../../components/Common/Subject.vue";
import { mapGetters } from "vuex";
import { isGetToken } from "@/utils/index";

export default {
  name: "NickName",
  components: {
    Nav,
    TitleBox,
  },
  computed: {
    ...mapGetters(["userDetail"]),
  },
  data() {
    return {
      title: "修改用户名",
      //账户信息
      nickName: "",
      // 规则信息
      rulesMsg: {
        name: "请输入用户名",
      },
      rulesError: {
        name: "用户名必填",
      },
    };
  },
  created() {
    // 是否有token
    isGetToken(this);
  },
  methods: {
    // 确定修改用户名
    updateNickName() {
      // 参数
      let params = {};
    },
  },
};
</script>
<style lang="scss">
@import "./user.scss";
</style>

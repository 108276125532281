<template>
  <div class="userBox">
    <!-- <TitleBox :titleText="title" /> -->
    <div class="userTitleBox" @click="goUserDetail">
      <div class="nameBox">
        <van-icon size="8vh" class-prefix="my-icon" name="1USER" />
        <span class="nameTextBox">{{
          userInfo && userInfo.name ? userInfo.name : "未知用户"
        }}</span>
      </div>
      <div class="goDetailBox">
        <van-icon size="3vh" name="arrow" />
      </div>
    </div>
    <div class="configBox" @click="goUserList" v-if="setPermission()">
      <div class="configTitleBox">
        <van-icon size="4vh" class-prefix="my-icon" name="yonghuguanli" />
        <span class="nameTextBox">用户管理</span>
      </div>
      <div class="goDetailBox"><van-icon size="3vh" name="arrow" /></div>
    </div>
    <!-- <div class="configBox" @click="toConditionConfig">
      <div class="configTitleBox">
        <van-icon size="4vh" class-prefix="my-icon" name="baojingshezhi" />
        <span class="nameTextBox">报警条件设置</span>
      </div>
      <div class="goDetailBox"><van-icon size="3vh" name="arrow" /></div>
    </div> -->
    <div class="configBox" @click="goVersion">
      <div class="configTitleBox">
        <van-icon size="4vh" class-prefix="my-icon" name="banben" />
        <span class="nameTextBox">版本信息</span>
      </div>
      <div class="goDetailBox"><van-icon size="3vh" name="arrow" /></div>
    </div>
    <div class="loginOutBox">
      <van-button @click="loginOut" class="buttonBox" block type="info"
        >退出登录</van-button
      >
    </div>
    <Nav />
  </div>
</template>
<script>
import Nav from "../../components/Common/Nav.vue";
import TitleBox from "../../components/Common/Subject.vue";
import { mapGetters } from "vuex";
import { isGetToken, changeJSONStr, isPermission } from "@/utils/index";

export default {
  name: "User",
  components: {
    Nav,
    TitleBox,
  },
  computed: {
    ...mapGetters(["whoami"]),
  },
  data() {
    return {
      title: "用户中心",
      userInfo: {},
    };
  },
  created() {
    // 是否有token
    isGetToken(this);
    // 请求用户信息
    this.$store.dispatch("getWhoami").then((res) => {
      let userData = res && res.msg ? res.msg : {};
      this.userInfo = userData;
      sessionStorage.setItem("whoami", changeJSONStr(userData));
    });
  },
  methods: {
    // 权限判断
    setPermission() {
      let result = isPermission();
      return result;
    },
    // 去往用户详情
    goUserDetail() {
      let id = this.userInfo && this.userInfo.id ? this.userInfo.id : "";
      let params = {
        id,
      };
      this.$router.push({
        path: "/user/detail",
        query: params,
      });
    },
    // 跳转用户管理列表
    goUserList() {
      this.$router.push("/user/list");
    },
    // 去往报警条件设置
    toConditionConfig() {
      this.$router.push("/user/condition");
    },
    // 去往版本信息
    goVersion() {
      this.$router.push("/user/version");
    },
    // 退出登录
    loginOut() {
      this.$store.dispatch("logOut");
    },
  },
};
</script>
<style lang="scss">
@import "./user.scss";
</style>

<template>
  <div class="userDetailBox">
    <TitleBox :titleText="title" :isBack="true" :backUrl="backUrl" />
    <div class="contentBox">
      <!-- <div class="configBox" @click="goNickName">
        <div class="configTitleBox">
          <van-icon size="4vh" class-prefix="my-icon" name="1USER" />
          <span class="nameTextBox">用户名设置</span>
        </div>
        <div class="goDetailBox"><van-icon size="3vh" name="arrow" /></div>
      </div> -->
      <div class="configBox" @click="goUserDetail">
        <div class="configTitleBox">
          <van-icon size="4vh" class-prefix="my-icon" name="mima" />
          <span class="nameTextBox">密码设置</span>
        </div>
        <div class="goDetailBox"><van-icon size="3vh" name="arrow" /></div>
      </div>
    </div>
    <Nav />
  </div>
</template>
<script>
import Nav from "../../components/Common/Nav.vue";
import TitleBox from "../../components/Common/Subject.vue";
import { isGetToken } from "@/utils/index";

export default {
  name: "UserDetail",
  components: {
    Nav,
    TitleBox,
  },
  data() {
    return {
      title: "个人信息",
      backUrl: "/user",
    };
  },
  created() {
    // 是否有token
    isGetToken(this);
  },
  methods: {
    // 修改昵称
    goNickName() {
      this.$router.push("/user/update/nickname");
    },
    // 修改密码
    goUserDetail() {
      let query = this.$route.query ? this.$route.query : {};
      this.$router.push({
        path: "/user/update/password",
        query: query,
      });
    },
  },
};
</script>
<style lang="scss">
@import "./user.scss";
</style>

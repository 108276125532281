<template>
  <div class="showFormBox">
    <van-form validate-first @submit="submitForm">
      <div v-for="(item, i) in this.formList" :key="i">
        <van-field
          v-if="item.type === 'input' && item.name !== 'password'"
          :label="item.label"
          :label-width="item.labelWidth"
          :name="item.name"
          v-model="modelDataForm[item.name]"
          :disabled="isDisabled"
          :rules="item.rules"
          :required="item.isRequired"
        />
        <van-field
          v-if="item.name === 'password' && !isUpdate"
          :label="item.label"
          :label-width="item.labelWidth"
          :name="item.name"
          v-model="modelDataForm[item.name]"
          :disabled="isDisabled"
          :rules="item.rules"
          :required="item.isRequired"
          :type="modelDataForm.passwordType"
        >
          <template slot="right-icon">
            <span class="solts" @click="switchPasswordType">
              <van-icon
                name="eye"
                v-if="modelDataForm.passwordType === 'text'"
              />
              <van-icon name="closed-eye" v-else />
            </span>
          </template>
        </van-field>
        <van-field
          v-if="item.type === 'picker'"
          readonly
          clickable
          :name="item.name"
          v-model="modelDataForm[item.name]"
          :label="item.label"
          :label-width="item.labelWidth"
          @click="
            !isDisabled
              ? (modelDataForm[`${item.name}` + 'ShowPicker'] = true)
              : null
          "
          :rules="item.rules"
          :required="item.isRequired"
          :disabled="isDisabled"
        />
        <van-popup
          v-model="modelDataForm[`${item.name}` + 'ShowPicker']"
          position="bottom"
        >
          <van-picker
            :default-index="modelDataForm[`${item.name}` + 'DefaultPicker']"
            :title="item.columsTitle"
            show-toolbar
            :columns="item.columns"
            @confirm="
              (val) => {
                onPickerConfirm(val, item.name);
              }
            "
            @cancel="modelDataForm[`${item.name}` + 'ShowPicker'] = false"
          />
        </van-popup>
      </div>
      <div class="buttonAllBox">
        <van-button
          class="buttonBox"
          block
          type="info"
          native-type="submit"
          :loading="loading"
          :style="{ display: !isDisabled ? 'block' : 'none' }"
        >
          确定
        </van-button>
        <van-button
          class="buttonBox"
          block
          native-type="button"
          @click="goBack"
        >
          取消
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { hasObjArr } from "@/utils/index";
import { mapGetters } from "vuex";
import { Toast } from "vant";
export default {
  name: "List",
  props: ["formList", "modelData", "isUpdate", "isDisabled"],
  computed: {
    ...mapGetters(["userDetail"]),
  },
  watch: {
    userDetail(data) {
      // 处理数据
      hasObjArr(data)
        ? this.formList.map((v) => {
            if (v.name !== "is_admin" && v.name !== "is_enabled") {
              this.modelDataForm[v.name] =
                data && data[v.name] ? data[v.name] : "";
            } else if (v.name === "is_admin" || v.name === "is_enabled") {
              this.modelDataForm[v.name] = data && data[v.name] ? "是" : "否";
              this.modelDataForm[v.name + "DefaultPicker"] =
                data && data[v.name] ? 0 : 1;
            }
          })
        : null;
    },
  },
  data() {
    return {
      modelDataForm: this.modelData,
      loading: false,
    };
  },
  created() {},
  methods: {
    // 确定picker选择
    onPickerConfirm(value, params) {
      this.modelDataForm[params] = value;
      // 关闭筛选
      this.modelDataForm[`${params}` + "ShowPicker"] =
        !this.modelDataForm[`${params}` + "ShowPicker"];
    },
    // 切换密码
    switchPasswordType() {
      this.modelDataForm.passwordType =
        this.modelDataForm.passwordType === "password" ? "text" : "password";
    },
    // 提交表单
    submitForm(val) {
      let params = {};
      params.name = val.name && val.name !== "" ? val.name : null;
      params.phone = val.phone && val.phone !== "" ? val.phone : null;
      params.email = val.email && val.email !== "" ? val.email : null;
      params.is_admin = val.is_admin && val.is_admin === "是" ? true : false;
      params.is_enabled =
        val.is_enabled && val.is_enabled === "是" ? true : false;
      // 创建
      if (!this.isUpdate) {
        params.password =
          val.password && val.password !== "" ? val.password : null;
        this.$store.dispatch("createUserInfo", params).then((res) => {
          Toast.success("创建成功");
          this.loading = false;
          this.$router.push("/user/list"); // 返回用户管理
        });
      } else {
        // 修改
        let query = this.$route.query ? this.$route.query : {};
        params.id = query && query.id ? Number(query.id) : null;
        this.$store.dispatch("updateUserInfo", params).then((res) => {
          Toast.success("修改成功");
          this.loading = false;
          this.$router.push("/user/list"); // 返回用户管理
        });
      }
    },
    // 返回用户列表
    goBack() {
      this.$router.push("/user/list");
    },
  },
};
</script>
<style lang="scss">
@import "./user.scss";
</style>

<template>
  <div class="realTimeBox">
    <TitleBox :titleText="title" :isFilter="false" :isBack="false" />
    <div class="contentBox">
      <RealTabBox :loading="loading" />
    </div>
    <Nav />
  </div>
</template>
<script>
import Nav from "../../components/Common/Nav.vue";
import TitleBox from "../../components/Common/Subject.vue";
import RealTabBox from "../../components/RealTime/RealTab.vue";
import { isGetToken } from "@/utils/index";

let timer = null;

export default {
  name: "RealTime",
  components: {
    Nav,
    TitleBox,
    RealTabBox,
  },
  data() {
    return {
      title: "实时监控",
      loading: false,
      laodingAlarm: false,
      timer: null,
    };
  },
  created() {
    // 是否有token
    isGetToken(this);
    if (this.$route.path !== "/login") {
      this.fetchRealTimeList(); // 请求实时数据
    }
  },

  beforeDestroy() {
    timer ? clearTimeout(timer) : null;
  },

  methods: {
    // 请求实时数据
    fetchRealTimeList() {
      if (
        this.$route.path === "/login" ||
        this.$route.path === "/history" ||
        this.$route.path === "/alarmlog" ||
        this.$route.path === "/user"
      ) {
        timerAlarm ? clearTimeout(timer) : null; //清除定时器
        return;
      }
      timer ? clearTimeout(timer) : null; //清除定时器
      this.loading = true;
      this.$store
        .dispatch("getRealTimeList")
        .then((res) => {
          this.loading = false;
          timer = setTimeout(() => {
            this.fetchRealTimeList(); // 请求实时数据
          }, 1000 * 2);
        })
        .catch((error) => {
          this.loading = false;
          timer = setTimeout(() => {
            this.fetchRealTimeList(); // 请求实时数据
          }, 1000 * 30);
        });
    },
  },
};
</script>
<style lang="scss">
@import "./realtime.scss";
</style>

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/comm.css'
import './assets/css/iconfont/iconfont.css'
// Vant
import Vant from 'vant';
import 'vant/lib/index.css';
// Element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// laodmore
import VueLoadmore from 'vuejs-loadmore';

import VConsole from 'vconsole';
const vConsole = process.env.VUE_APP_NODE_ENV === 'dev' ? new VConsole() : null;

// axios 拦截器
// import './http/axios'

// // 对后端接口 进行全局注册
// Vue.prototype.$api = api;
// // 对请求方式 进行全局注册
// Vue.prototype.$http = http;


Vue.config.productionTip = false
// 注册
Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(VueLoadmore);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

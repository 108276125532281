<template>
  <div class="tableBox">
    <el-table
      v-loading="this.loading"
      :data="tableData"
      height="calc(100vh - 30vh)"
      @cell-click="updateUser"
    >
      <el-table-column label="名称" align="center" prop="name">
        <template slot-scope="scope">
          <span
            :style="{ display: 'block' }"
            @touchstart="showDeleteButton(scope.row)"
            @touchend="clearLoop"
            >{{ scope.row.name }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="手机号" align="center" prop="phone">
        <template slot-scope="scope">
          <span
            :style="{ display: 'block' }"
            @touchstart="showDeleteButton(scope.row)"
            @touchend="clearLoop"
            >{{ scope.row.phone }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="邮箱" align="center" prop="email">
        <template slot-scope="scope">
          <span
            :style="{ display: 'block' }"
            @touchstart="showDeleteButton(scope.row)"
            @touchend="clearLoop"
            >{{ scope.row.email }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="是否为管理员" align="center" prop="is_admin">
        <template slot-scope="scope">
          <span
            :style="{ display: 'block' }"
            @touchstart="showDeleteButton(scope.row)"
            @touchend="clearLoop"
            >{{ scope.row.is_admin ? "是" : "否" }}</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :total="total"
      :current-page="this.page"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>
<script>
import { hasObjArr } from "@/utils/index";
import { mapGetters } from "vuex";
import { Dialog, Toast } from "vant";
export default {
  name: "List",
  props: [
    "loading",
    "fetchUserList",
    "filterParams",
    "page",
    "pageSize",
    "changePage",
  ],
  computed: {
    ...mapGetters(["userList"]),
  },
  watch: {
    userList(data) {
      let tableData = [];
      tableData = this.setTableData(data); //处理数据
      this.tableData = tableData;
      this.total = data.length;
    },
    page(pageValue) {
      let userList = [...this.userList]; //表格数据
      let tableData = [];
      tableData = this.setTableData(userList); //处理数据
      this.tableData = tableData;
    },
  },
  data() {
    return {
      tableData: [],
      total: 0,
      Loop: "",
    };
  },
  created() {},
  methods: {
    // 处理数据
    setTableData(data) {
      let tableData = [];
      let sliceData = [];
      // 处理分页数据
      if (hasObjArr(data)) {
        sliceData = data.slice(
          (this.page - 1) * this.pageSize,
          this.page * this.pageSize
        );
      }
      hasObjArr(sliceData)
        ? sliceData.map((v) => {
            let obj = { ...v };
            obj.key = v.id ? v.id : -1;
            hasObjArr(obj) ? tableData.push(obj) : null;
          })
        : null;
      return tableData;
    },
    //   长按刚开始的时候
    showDeleteButton(row) {
      let that = this;
      clearInterval(this.Loop); //清理掉计时器，防止重复注册定时器
      this.Loop = setTimeout(function () {
        Dialog.confirm({
          title: "提示",
          message: "删除该用户",
        })
          .then(() => {
            // 参数
            let params = {};
            params.id = row.id;
            that.$store
              .dispatch("deleteUserInfo", params)
              .then((res) => {
                // 请求用户列表
                that.fetchUserList(that.filterParams);
                Toast.success("删除成功");
              })
              .catch((error) => {
                // Toast.fail("删除失败");
              });
          })
          .catch(() => {});
      }, 800);
    },
    //   长按松手的时候
    clearLoop() {
      //松手就清理掉计时器，也就是如果长按时间没到0.5秒，则取消长按事件的触发
      clearInterval(this.Loop);
    },
    // 修改用户
    updateUser(val) {
      let id = val && val.id ? val.id : "";
      let params = {
        id,
        type: "update",
      };
      this.$router.push({
        path: "/user/show",
        query: params,
      });
    },
  },
};
</script>
<style lang="scss">
@import "./user.scss";
</style>

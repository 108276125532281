<template>
  <div class="login">
    <div class="content">
      <!-- 头部logo -->
      <div class="header">
        <div class="titleBox">
          <div class="logoBox">
            <img :src="logo" alt="" />
          </div>
          <span class="text">{{ siteTitle }}</span>
        </div>
      </div>
      <!-- 主体表单 -->
      <div class="main">
        <van-form validate-first @failed="onFailed" @submit="onFished">
          <!-- 账号 -->
          <van-field
            label="账号"
            label-width="3em"
            left-icon="user-o"
            name="name"
            v-model="accountInfo.name"
            :placeholder="rulesMsg.name"
            :rules="[{ validator, message: rulesError.name }]"
          />
          <!-- 密码 -->
          <van-field
            label="密码"
            label-width="3em"
            left-icon="shield-o"
            name="password"
            v-model="accountInfo.password"
            :type="passwordType"
            :placeholder="rulesMsg.password"
            :rules="[{ pattern: rules.password, message: rulesError.password }]"
          >
            <template slot="right-icon">
              <span class="solts" @click="switchPasswordType">
                <van-icon name="eye" v-if="passwordType === 'text'" />
                <van-icon name="closed-eye" v-else />
              </span>
            </template>
          </van-field>
          <div style="margin: 16px">
            <van-button
              class="buttonBox"
              block
              type="info"
              native-type="submit"
            >
              提交
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import logoImg from "@/assets/images/logo.png";
export default {
  name: "Login",
  data() {
    return {
      siteTitle: process.env.VUE_APP_TITLE || "PLC同步顶升实时监测系统",
      logo: logoImg,
      // 是否展示密码
      passwordType: "password",
      //账户信息
      accountInfo: {
        name: "",
        password: "",
      },
      // 规则
      rules: {
        name: /^1(3|4|5|6|7|8|9)\d{9}$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,50}$/,
      },
      // 规则信息
      rulesMsg: {
        name: "请输入手机号或邮箱",
        password: "请输入密码",
      },
      rulesError: {
        name: "手机号或邮箱格式错误",
        password: "密码必须包含大小写字母和数字，长度不能少于8位",
      },
    };
  },
  created() {
    // 清除本地存储
    sessionStorage.removeItem('token')
    localStorage.removeItem("token");
    sessionStorage.removeItem("whoami");
    sessionStorage.removeItem("realTimeData");
  },
  methods: {
    // 密码展示
    switchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    // 验证是否为用户名或密码
    validator(val) {
      let isPass = false;
      // 验证是否为邮箱
      if (val.includes("@")) {
        let regMobileEmail =
          /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        val && val !== "" && regMobileEmail.test(val)
          ? (isPass = true)
          : (isPass = false);
      } else {
        let regMobilePhone = /^1(3|4|5|6|7|8|9)\d{9}$/;
        val && val !== "" && regMobilePhone.test(val)
          ? (isPass = true)
          : (isPass = false);
      }
      return isPass;
    },
    // 表单验证失败
    onFailed(errorInfo) {},
    // 表单验证成功
    onFished(info) {
      // 参数
      let params = {};
      params.account = info && info.name ? info.name : "";
      params.password = info && info.password ? info.password : "";
      this.$store.dispatch("login", params);
    },
  },
};
</script>
<style lang="scss">
@import "./login.scss";
</style>

import { queryRealTime } from '@/api/realTime'

const realTime = {
    state: {
        realTimeList: [],
    },

    mutations: {
        // 获取实时数据
        SET_REALTIMELIST: (state, realTimeList) => {
            state.realTimeList = realTimeList
        },
    },

    actions: {
        // 获取实时数据
        getRealTimeList({ commit }, params) {
            return new Promise((resolve, reject) => {
                queryRealTime(params).then(res => {
                    commit('SET_REALTIMELIST', res && res.msg ? res.msg : [])
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}

export default realTime

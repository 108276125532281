const getters = {
    // tokenInfo
    token: state => state.user.token,
    whoami: state => state.user.whoami,
    userList: state => state.user.userList,
    userDetail: state => state.user.userDetail,
    realTimeList: state => state.realTime.realTimeList,
    historyList: state => state.history.historyList,
    alarmLogList: state => state.alarmLog.alarmLogList,
    isAlert: state => state.alarmLog.isAlert,
}
export default getters

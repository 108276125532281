<template>
  <div class="showUserBox">
    <TitleBox :titleText="title" :isBack="true" />
    <div class="contentBox">
      <ShowFormBox
        :formList="formList"
        :modelData="modelData"
        :isUpdate="isUpdate"
        :isDisabled="isDisabled"
      />
    </div>
    <Nav />
  </div>
</template>
<script>
import Nav from "../../components/Common/Nav.vue";
import TitleBox from "../../components/Common/Subject.vue";
import ShowFormBox from "../../components/User/ShowForm.vue";
import { isGetToken } from "@/utils/index";

export default {
  name: "List",
  components: {
    Nav,
    TitleBox,
    ShowFormBox,
  },

  data() {
    return {
      title: "用户详情",
      formList: [],
      modelData: [],
      isUpdate: false,
      isDisabled: false,
    };
  },
  created() {
    // 是否有token
    isGetToken(this);
    // 获取用户id
    let query = this.$route.query;
    this.title = query && query.type === "create" ? "新增用户" : "修改用户";
    // 请求用户详情信息
    query.id && query.id ? this.fetchUserDetail(query.id) : null;
    query && query.id ? (this.isUpdate = true) : null;
    // 是否可以修改
    // 表格数据
    let formList = [
      {
        label: "用户名称",
        name: "name",
        labelWidth: "4em",
        type: "input",
        rules: [{ required: true, message: "请填写用户名" }],
        isRequired: true,
      },
      {
        label: "手机号",
        name: "phone",
        labelWidth: "4em",
        type: "input",
        rules: [
          { required: true, message: "请填写手机号" },
          { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: "手机号格式错误" },
        ],
        isRequired: true,
      },
      {
        label: "邮箱",
        name: "email",
        labelWidth: "4em",
        type: "input",
        rules: [{ required: false, message: "请填写邮箱" }],
        isRequired: false,
      },
      {
        label: "密码",
        name: "password",
        labelWidth: "4em",
        type: "input",
        required: true,
        rules: [
          { required: true, message: "请填写密码" },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,50}$/,
            message: "密码必须包含大小写字母和数字，长度不能少于8位",
          },
        ],
        isRequired: true,
      },
      {
        label: "管理員",
        name: "is_admin",
        labelWidth: "4em",
        type: "picker",
        columsTitle: "是否为管理员",
        columns: ["是", "否"],
        rules: [{ required: true, ruleMessage: "请选择是否为管理员" }],
        isRequired: true,
      },
      {
        label: "是否可用",
        name: "is_enabled",
        labelWidth: "4em",
        type: "picker",
        columsTitle: "是否可用",
        columns: ["是", "否"],
        rules: [{ required: true, ruleMessage: "请选择是否可用" }],
        isRequired: true,
      },
    ];
    // model数据
    let modelData = {
      // 参数
      name: "",
      phone: "",
      email: "",
      password: "",
      is_admin: "否",
      is_enabled: "是",
      // password
      passwordType: "password",
      // pciker select
      is_adminShowPicker: false,
      is_adminDefaultPicker: 1,
      is_enabledShowPicker: false,
      is_enabledDefaultPicker: 0,
    };
    this.formList = formList;
    this.modelData = modelData;
  },
  methods: {
    //   用户详情信息
    fetchUserDetail(id) {
      let params = {};
      params.id = id;
      this.$store.dispatch("getUserDetail", params).then((res) => {
        // 用户详情
        let userData = res && res.msg ? res.msg : {};
        // 登录用户
        let whoamiData = sessionStorage.getItem("whoami")
          ? JSON.parse(sessionStorage.getItem("whoami"))
          : null;
        if (whoamiData && whoamiData.is_admin) {
          this.isDisabled = false;
        } else {
          let userId = userData.id;
          let whoamiId = whoamiData && whoamiData.id ? whoamiData.id : null;
          this.isDisabled = userId === whoamiId ? false : true;
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "./user.scss";
</style>

import axios from 'axios'
import { Notification, Message } from 'element-ui'
import { Toast } from 'vant';
import store from '@/store'
import { getToken } from '@/utils/index'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_SERVE_URL,
    // 超时
    timeout: 100000
})
// request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        config.headers['Authorization'] = getToken() // 让每个请求携带自定义token,根据实际情况自行修改
    }
    // get请求映射params参数
    if ((config.method === 'get' && config.params) || (config.method === 'delete' && config.params)) {
        let url = config.url + '?';
        let configData = config.params
        for (const propName of Object.keys(configData)) {
            const value = configData[propName];
            var part = encodeURIComponent(propName) + "=";
            if (value !== null && typeof (value) !== "undefined") {
                if (typeof value === 'object') {
                    for (const key of Object.keys(value)) {
                        if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                            let params = propName + '[' + key + ']';
                            let subPart = encodeURIComponent(params) + '=';
                            url += subPart + encodeURIComponent(value[key]) + '&';
                        }
                    }
                } else {
                    url += part + encodeURIComponent(value) + "&";
                }
            }
        }
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = res.data.msg
    if (code === 1200 || code === 1201 || code === 1202 || code === 1203) {
        return res.data
    }
    if (code === 401) {
        Message({
            message: '登录验证失败，请重新登录',
            type: 'error'
        })
        store.dispatch('logOut')
    } else if (code === 500) {
        Message({
            message: msg,
            type: 'error'
        })
        return Promise.reject(new Error(msg))
    } else {
        Notification.error({
            title: msg
        })
        return Promise.reject('error')
    }
},
    error => {
        let { message, response } = error;
        let url = response && response.config && response.config.url ? response.config.url : null //url
        let errorCode = response && response.data ? response.data : null //返回信息
        if (errorCode.code === 401) {
            Message({
                message: '登录验证失败，请重新登录',
                type: 'error'
            })
            store.dispatch('logOut')
        } else {
            if (message == "Network Error") {
                message = "后端接口连接异常";
            }
            else if (message.includes("timeout")) {
                message = "系统接口请求超时";
            }
            else if (url === '/login/' && errorCode) {
                if (errorCode.code === 1000) {
                    message = "用户未找到";
                }
                if (errorCode.code === 1002) {
                    message = "账户或密码错误";
                } else {
                    message = "用户未找到";
                }
            }
            else if (message.includes("Request failed with status code")) {
                message = response && response.data && response && response.data.error ? response.data.error : "系统接口" + message.substr(message.length - 3) + "异常";
            }
            Toast.fail(message);
        }
        // Message({
        //     message: message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
    }
)

export default service

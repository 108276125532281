import request from '@/utils/request'

// Get RealTimeData
export function queryRealTime(data) {
    return request({
        url: '/jack_data/realtime/',
        method: 'get',
        params: data
    })
}

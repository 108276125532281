<template>
  <div class="passwordBox">
    <TitleBox :titleText="title" :isBack="true" />
    <div class="contentBox">
      <div :class="currentStep === 0 ? 'stepShow' : 'stepHidden'">
        <van-form validate-first @submit="next">
          <!-- 登录密码 -->
          <van-field
            label="登录密码"
            label-width="4em"
            name="oldPassword"
            v-model="passworeData.oldPassword"
            :type="passwordTypeOld"
            :placeholder="rulesMsg.oldPassword"
            :rules="[{ pattern: rules.password, message: rulesError.password }]"
          >
            <template slot="right-icon">
              <span class="solts" @click="switchPasswordType('old')">
                <van-icon name="eye" v-if="passwordTypeOld === 'text'" />
                <van-icon name="closed-eye" v-else />
              </span>
            </template>
          </van-field>
          <div class="buttonAllBox">
            <van-button
              class="buttonBox"
              block
              type="info"
              native-type="submit"
            >
              下一步
            </van-button>
          </div>
        </van-form>
      </div>
      <div :class="currentStep === 1 ? 'stepShow' : 'stepHidden'">
        <van-form validate-first @submit="finished">
          <!-- 新密码 -->
          <van-field
            label="新密码"
            label-width="4em"
            name="newPassword"
            v-model="passworeData.newPassword"
            :type="passwordTypeNew"
            :placeholder="rulesMsg.newPassword"
            :rules="[{ pattern: rules.password, message: rulesError.password }]"
          >
            <template slot="right-icon">
              <span class="solts" @click="switchPasswordType('new')">
                <van-icon name="eye" v-if="passwordTypeNew === 'text'" />
                <van-icon name="closed-eye" v-else />
              </span>
            </template>
          </van-field>
          <!-- 重复密码 -->
          <van-field
            label="重复密码"
            label-width="4em"
            name="rePassword"
            v-model="passworeData.rePassword"
            :type="passwordTypeRe"
            :placeholder="rulesMsg.rePassword"
            :rules="[
              { pattern: rules.password, message: rulesError.password },
              {
                validator,
                message: rulesError.rePassword,
              },
            ]"
          >
            <template slot="right-icon">
              <span class="solts" @click="switchPasswordType('re')">
                <van-icon name="eye" v-if="passwordTypeRe === 'text'" />
                <van-icon name="closed-eye" v-else />
              </span>
            </template>
          </van-field>
          <div class="buttonAllBox">
            <van-button
              class="buttonBox"
              block
              type="info"
              native-type="button"
              @click="last"
            >
              上一步
            </van-button>
            <van-button
              class="buttonBox"
              block
              type="info"
              native-type="submit"
              :loading="loading"
            >
              完成
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
    <Nav />
  </div>
</template>
<script>
import Nav from "../../components/Common/Nav.vue";
import TitleBox from "../../components/Common/Subject.vue";
import { isGetToken } from "@/utils/index";
import { Toast } from "vant";

export default {
  name: "Password",
  components: {
    Nav,
    TitleBox,
  },
  data() {
    return {
      title: "修改密码",
      currentStep: 0,
      passworeData: {
        oldPassword: "",
        newPassword: "",
        rePassword: "",
      },
      // 是否展示密码
      passwordTypeOld: "password",
      passwordTypeNew: "password",
      passwordTypeRe: "password",
      // 规则
      rules: {
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,50}$/,
      },
      // 规则信息
      rulesMsg: {
        oldPassword: "请输入登录密码",
        newPassword: "请输入新密码",
        rePassword: "请重复密码",
      },
      rulesError: {
        name: "手机号格式错误",
        password: "密码必须包含大小写字母和数字，长度不能少于8位",
        rePassword: "输入的密码不一致，请重新输入",
      },
      loading: false,
    };
  },
  created() {
    // 是否有token
    isGetToken(this);
    // 请求用户信息
  },
  methods: {
    // 密码展示
    switchPasswordType(type) {
      switch (type) {
        case "old":
          this.passwordTypeOld =
            this.passwordTypeOld === "password" ? "text" : "password";
          break;
        case "new":
          this.passwordTypeNew =
            this.passwordTypeNew === "password" ? "text" : "password";
          break;
        case "re":
          this.passwordTypeRe =
            this.passwordTypeRe === "password" ? "text" : "password";
          break;
      }
    },
    // 验证重复密码
    validator(val) {
      // 新密码
      let newPassword =
        this.passworeData && this.passworeData.newPassword
          ? this.passworeData.newPassword
          : "";
      return val === newPassword;
    },
    // 上一步
    last() {
      this.currentStep = this.currentStep - 1;
    },
    // 下一步
    next() {
      let that = this;
      let whoAmIInfo = sessionStorage.getItem("whoami")
        ? JSON.parse(sessionStorage.getItem("whoami"))
        : null;
      let params = {};
      whoAmIInfo && whoAmIInfo.id ? (params.id = whoAmIInfo.id) : null;
      params.password = this.passworeData.oldPassword;
      // 验证密码
      this.$store.dispatch("checkPasswordInfo", params).then((res) => {
        let isNext = res && res.msg ? true : false; //是否进行下一步
        if (isNext) {
          that.currentStep = that.currentStep + 1;
        } else {
          Toast.fail('登录密码错误');
        }
      });
    },
    // 完成
    finished() {
      let query = this.$route.query ? this.$route.query : {};
      let id = query && query.id ? Number(query.id) : null; //用户id
      // 参数
      let params = {};
      params.id = id;
      params.password =
        this.passworeData && this.passworeData.newPassword
          ? this.passworeData.newPassword
          : null;
      this.loading = true;
      // 修改密码
      this.$store
        .dispatch("changePasswordInfo", params)
        .then(() => {
          this.$router.push("/user"); //返回用户中心
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import "./user.scss";
</style>

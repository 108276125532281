<template>
  <div class="historyBox">
    <TitleBox
      :titleText="title"
      :isFilter="true"
      :filterList="filterList"
      :modelData="modelData"
      :submitFilter="this.submitFilter"
    />
    <div class="contentBox">
      <HistoryListBox
        :onLoad="this.onLoad"
        :finished="finished"
        :filterParams="filterParams"
      />
    </div>
    <Nav />
  </div>
</template>
<script>
import Nav from "../../components/Common/Nav.vue";
import TitleBox from "../../components/Common/Subject.vue";
import HistoryListBox from "../../components/History/HistoryList.vue";
import { mapGetters } from "vuex";
import { isGetToken, hasObjArr, isNullOrUndefined } from "@/utils/index";

export default {
  name: "History",
  components: {
    Nav,
    TitleBox,
    HistoryListBox,
  },
  computed: {
    ...mapGetters(["historyList"]),
  },
  data() {
    return {
      title: "历史记录",
      filterList: [],
      modelData: [],
      filterParams: {},
      page: 1,
      page_size: 10,
      finished: false,
    };
  },
  created() {
    // 是否有token
    isGetToken(this);
    // 筛选的数据
    let filterList = [
      {
        label: "起始时间",
        name: "start_date",
        labelWidth: "4em",
        type: "dateTime",
        dateTimerPicker: "datetime",
      },
      {
        label: "终止时间",
        name: "end_date",
        labelWidth: "4em",
        type: "dateTime",
        dateTimerPicker: "datetime",
      },
    ];
    // model数据
    let modelData = {
      // 参数
      start_date: "",
      end_date: "",
      // pciker select
      start_dateDateTime: false,
      end_dateDateTime: false,
    };
    this.filterList = filterList;
    this.modelData = modelData;
    this.fetchHistoryList();
  },
  methods: {
    // 确定筛选
    submitFilter(data) {
      // 请求历史记录
      let changeData = {};
      data && data.start_date && data.start_date !== ""
        ? (changeData.start_date = data.start_date + ":00")
        : null;
      data && data.end_date && data.end_date !== ""
        ? (changeData.end_date = data.end_date + ":00")
        : null;
      this.filterParams = changeData;
      this.page = 1;
      this.fetchHistoryList(changeData);
    },
    // 请求历史记录
    fetchHistoryList(data) {
      // 处理参数
      let params = {};
      params.page = this.page;
      params.page_size = this.page_size;
      this.filterList.map((v) => {
        hasObjArr(data) && isNullOrUndefined(data[v.name])
          ? (params[v.name] = data[v.name])
          : null;
      });
      this.$store.dispatch("getHistoryList", params);
    },
    // 加载数据
    onLoad(done) {
      // 当前页数
      let pageNow = this.historyList.current_page
        ? this.historyList.current_page
        : 1;
      // 总页数
      let totalPage = this.historyList.total_page
        ? this.historyList.total_page
        : 1;
      if (pageNow < totalPage) {
        this.page++;
        this.fetchHistoryList(this.filterParams);
      } else {
        // all data loaded
        this.finished = true;
      }
      done();
    },
  },
};
</script>
<style lang="scss">
@import "./history.scss";
</style>

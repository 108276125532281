<template>
  <div class="historyListBox">
    <vue-loadmore :on-loadmore="onLoad" :finished="finished" v-if="list.length">
      <div v-for="(item, i) of list" :key="i">
        <el-card class="cardBox">
          <div slot="header" class="clearfix">
            <span>{{ item.time }}</span>
          </div>
          <div class="deviceDataBox">
            <div class="deviceTopBox">
              <div class="paddingBox"></div>
              <div class="deviceIconAllBox">
                <div
                  class="deviceIonBox"
                  v-for="(item, i) in deviceIconList"
                  :key="i"
                >
                  <span class="textBox">{{ item }}</span>
                </div>
              </div>
            </div>
            <div class="decviceContentBox">
              <div
                class="realContentBox"
                v-for="(itemTitle, i) in deviceContentList"
                :key="i"
              >
                <div class="realIconBox">
                  <span class="textBox">{{ itemTitle.name }}</span>
                </div>
                <div class="realValueGroupBox">
                  <span v-for="(itemChild, i) in [1, 2, 3, 4]" :key="i">
                    {{
                      item.changeData[itemTitle.type + `${itemChild}`] || 0
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </vue-loadmore>
    <div v-else>
      <NoDataBox />
    </div>
  </div>
</template>
<script>
import { hasObjArr, compare } from "@/utils/index";
import { mapGetters } from "vuex";
import moment from "moment";
import NoDataBox from "../Common/NoData.vue";
export default {
  name: "HistoryList",
  props: ["onLoad", "finished", "filterParams"],
  components: {
    NoDataBox,
  },
  computed: {
    ...mapGetters(["historyList"]),
  },
  watch: {
    // 重置数据
    filterParams(val) {
      this.list = [];
    },
    historyList(val) {
      let list = [...this.list];
      let historyData = val && hasObjArr(val.data) ? val.data : []; //历史数据
      // 处理数据
      let historyDataChange = hasObjArr(historyData)
        ? historyData.map((v) => {
            v.time = moment(v.created_at).format("YYYY-MM-DD HH:mm:ss");
            let realTimeData =
              v.data && hasObjArr(JSON.parse(v.data).d)
                ? JSON.parse(v.data).d
                : [];
            // 按照时间处理
            let fitTimeData = [];
            hasObjArr(realTimeData)
              ? realTimeData.map((v) => {
                  fitTimeData.push({
                    name: v.pid,
                    value: v.v,
                    time: v.s * 1000 + v.ms,
                  });
                })
              : null;
            let sortTimeData = hasObjArr(fitTimeData)
              ? fitTimeData.sort((a, b) => {
                  return compare(b, a, "time", "data");
                })
              : [];
            // 删除多余数据
            let changeData = {};
            hasObjArr(sortTimeData)
              ? sortTimeData.map((val) => {
                  changeData.hasOwnProperty(val.name)
                    ? null
                    : (changeData[val.name] = val.value);
                })
              : null;
            v.changeData = changeData;
            return v;
          })
        : null;
      hasObjArr(historyDataChange)
        ? historyDataChange.map((val) => {
            list.push(val);
          })
        : null;
      this.list = list;
    },
  },

  data() {
    return {
      list: [],
      deviceIconList: ["1", "2", "3", "4"],
      deviceContentList: [
        {
          name: "工作状态",
          type: "workstate",
        },
        {
          name: "吨位 (T)",
          type: "capacity",
        },
        {
          name: "同步距离 (mm)",
          type: "relative",
        },
        {
          name: "绝对距离 (mm)",
          type: "absolute",
        },
      ],
    };
  },
  methods: {},
};
</script>  
<style lang="scss">
@import "./history.scss";
</style>
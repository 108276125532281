import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import realTime from './modules/realTime'
import history from './modules/history'
import alarmLog from './modules/alarmLog'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    realTime,
    history,
    alarmLog,
  },
  getters
})

export default store

import { queryAlarmLog, queryDeviceAlarmStatus } from '@/api/alarmLog'

const alarmLog = {
    state: {
        alarmLogList: {},
        alarmStatusList: {},
        isAlert: 'false'
    },

    mutations: {
        // 获取报警记录
        SET_ALARMLOGLIST: (state, alarmLogList) => {
            state.alarmLogList = alarmLogList
        },
        // 获取报警状态
        SET_ALARMSTATUSLIST: (state, alarmStatusList) => {
            state.alarmStatusList = alarmStatusList
        },
        // 是否有报警
        SET_ALERT: (state, isAlert) => {
            state.isAlert = isAlert
        },
    },

    actions: {
        // 获取报警记录数据
        getAlarmLogList({ commit }, params) {
            return new Promise((resolve, reject) => {
                queryAlarmLog(params).then(res => {
                    commit('SET_ALARMLOGLIST', res && res.msg ? res.msg : {})
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 获取报警状态
        getAlarmStatusList({ commit }, params) {
            return new Promise((resolve, reject) => {
                queryDeviceAlarmStatus(params).then(res => {
                    commit('SET_ALARMSTATUSLIST', res && res.msg ? res.msg : {})
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 是否有报警
        setAlert({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('SET_ALERT', params.isAlert)
            })
        },
    }
}

export default alarmLog

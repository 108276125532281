<template>
  <div class="noDataBox">
    <van-icon size="10vh" class-prefix="my-icon" name="nodata" />
    <p>暂无数据</p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss">
@import "../../assets/css/common.scss";
.noDataBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 5vw;
    color: $noDataColor;
  }
}
</style>

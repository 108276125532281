<template>
  <div class="listBox">
    <TitleBox
      :titleText="title"
      :isBack="true"
      :backUrl="backUrl"
      :isFilter="true"
      :filterList="filterList"
      :modelData="modelData"
      :submitFilter="this.submitFilter"
    />
    <div class="contentBox">
      <div class="buttonAllBox">
        <van-button
          v-if="setPermission()"
          size="small"
          class="buttonBox"
          block
          type="info"
          @click="goCreateUser"
          >创建用户</van-button
        >
      </div>
      <TableBox
        :loading="loading"
        :filterParams="filterParams"
        :page="page"
        :pageSize="pageSize"
        :changePage="changePage"
        :fetchUserList="this.fetchUserList"
      />
    </div>
    <Nav />
  </div>
</template>
<script>
import Nav from "../../components/Common/Nav.vue";
import TitleBox from "../../components/Common/Subject.vue";
import TableBox from "../../components/User/Table.vue";
import {
  isGetToken,
  hasObjArr,
  isNullOrUndefined,
  isPermission,
} from "@/utils/index";

export default {
  name: "List",
  components: {
    Nav,
    TitleBox,
    TableBox,
  },
  data() {
    return {
      title: "用户管理",
      backUrl: "/user",
      filterParams: {},
      filterList: [],
      modelData: [],
      loading: false,
      page: 1,
      pageSize: 10,
    };
  },
  created() {
    // 是否有token
    isGetToken(this);
    // 筛选的数据
    let filterList = [
      {
        label: "名称",
        name: "name",
        labelWidth: "3em",
        type: "input",
        clearable: true,
      },
      {
        label: "手机号",
        name: "phone",
        labelWidth: "3em",
        type: "input",
        clearable: true,
      },
      {
        label: "邮箱",
        name: "email",
        labelWidth: "3em",
        type: "input",
        clearable: true,
      },
      {
        label: "管理員",
        name: "is_admin",
        labelWidth: "3em",
        type: "picker",
        columsTitle: "是否为管理员",
        columns: ["全部", "是", "否"],
      },
    ];
    // model数据
    let modelData = {
      // 参数
      name: "",
      phone: "",
      email: "",
      is_admin: "全部",
      // pciker select
      is_adminShowPicker: false,
      is_adminDefaultPicker: 0,
    };
    this.filterList = filterList;
    this.modelData = modelData;
    // 请求用户列表
    this.fetchUserList();
  },
  methods: {
    // 权限判断
    setPermission() {
      let result = isPermission();
      return result;
    },
    // 确定筛选
    submitFilter(data) {
      // 请求用户列表
      this.filterParams = data;
      this.fetchUserList(data);
    },
    // 请求用户列表
    fetchUserList(data) {
      // 处理参数
      let params = {};
      this.filterList.map((v) => {
        if (v.name !== "is_admin") {
          hasObjArr(data) && isNullOrUndefined(data[v.name])
            ? (params[v.name] = data[v.name])
            : null;
        } else {
          if (hasObjArr(data) && data[v.name] !== "全部") {
            params[v.name] = data[v.name] === "是" ? true : false;
          }
        }
      });
      this.loading = true;
      this.page = 1;
      this.$store.dispatch("getUserList", params).then(() => {
        this.loading = false;
      });
    },
    // 切换页数
    changePage(page) {
      this.page = page;
    },
    // 创建用户
    goCreateUser() {
      let params = {
        type: "create",
      };
      this.$router.push({
        path: "/user/show",
        query: params,
      });
    },
  },
};
</script>
<style lang="scss">
@import "./user.scss";
</style>

import request from '@/utils/request'

// Get RealTimeData
export function queryHistory(data) {
    return request({
        url: '/jack_data/paginate/',
        method: 'get',
        params: data
    })
}

import request from '@/utils/request'

// Get AlarmLogData
export function queryAlarmLog(data) {
    return request({
        url: '/alert_record/',
        method: 'get',
        params: data
    })
}

// Get DeviceAlarmStatus
export function queryDeviceAlarmStatus(data) {
    return request({
        url: '/alert/',
        method: 'get',
        params: data
    })
}

<template>
  <div class="nav">
    <van-tabbar
      v-model="active"
      @change="changeActive"
      active-color="#00a0e9"
      inactive-color="#898989"
    >
      <van-tabbar-item>
        <div class="globalModelBox">
          <van-icon size="3vh" class-prefix="my-icon" name="zonglan" />
          <span class="textBox">实时监控</span>
        </div>
      </van-tabbar-item>
      <van-tabbar-item>
        <div class="globalModelBox">
          <van-icon size="3vh" class-prefix="my-icon" name="zonglan01" />
          <span class="textBox">历史记录</span>
        </div>
      </van-tabbar-item>
      <van-tabbar-item>
        <div class="globalModelBox">
          <el-badge :is-dot="is_alert" class="item">
            <van-icon size="3vh" class-prefix="my-icon" name="gbaojings" />
          </el-badge>
          <span class="textBox">状态记录</span>
        </div>
      </van-tabbar-item>
      <van-tabbar-item>
        <div class="globalModelBox">
          <van-icon size="3vh" class-prefix="my-icon" name="yonghu" />
          <span class="textBox">用户中心</span>
        </div>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { changeJSONStr } from "@/utils/index";

export default {
  props: {
    // curr: {
    //   type: Number,
    //   default: () => {
    //     return 0;
    //   },
    // },
  },
  computed: {
    ...mapGetters(["isAlert"]),
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to, from) {
        //跳转的路由
        let toPath = to && to.path ? to.path : "";
        switch (toPath) {
          case "/":
            this.active = 0;
            break;
          case "/history":
            this.active = 1;
            break;
          case "/alarmlog":
            this.active = 2;
            break;
          case "/user":
            this.active = 3;
            break;
          default:
            this.active = -1;
            break;
        }
      },
    },
    isAlert(val) {
      if (!sessionStorage.getItem("is_alert")) {
        sessionStorage.setItem("is_alert", val);
      }
      this.is_alert = val === "true" ? true : false;
    },
  },
  data() {
    let is_alert = sessionStorage.getItem("is_alert");
    return {
      active: 0,
      is_alert: is_alert === "true" ? true : false,
    };
  },
  created() {},
  methods: {
    changeActive(e) {
      this.active = e;
      switch (e) {
        case 0:
          this.$router.push("/");
          break;
        case 1:
          this.$router.push("/history");
          break;
        case 2:
          this.$router.push("/alarmlog");
          break;
        case 3:
          this.$router.push("/user");
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.nav {
  .van-tabbar--fixed {
    padding-bottom: 5px;
  }
  .globalModelBox {
    text-align: center;
    font-size: 14px;
    .textBox {
      display: block;
      margin-top: 6px;
      font-size: 2vh;
    }
  }
}
</style>

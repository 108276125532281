<template>
  <div class="deviceListBox">
    <div class="deviceTopBox">
      <div class="paddingBox"></div>
      <div class="deviceIconAllBox">
        <div class="deviceIonBox" v-for="(item, i) in deviceIconList" :key="i">
          <van-icon
            size="3vh"
            class-prefix="my-icon"
            name="shebeiguanli"
            class="iconBox"
          />
          <span class="textBox">{{ item }}</span>
        </div>
      </div>
    </div>
    <div class="decviceContentBox">
      <div
        class="realContentBox"
        v-for="(item, i) in deviceContentList"
        :key="i"
      >
        <div class="realIconBox">
          <!-- <van-icon
            size="2vh"
            class-prefix="my-icon"
            name="1USER"
            class="iconBox"
          /> -->
          <span class="textBox">{{ item.name }}</span>
        </div>
        <div class="realValueGroupBox">
          <span v-for="(itemChild, i) in [1, 2, 3, 4]" :key="i">{{
            deviceData[item.type + `${itemChild}`]
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { changeJSONStr, hasObjArr, compare } from "@/utils/index";
import { mapGetters } from "vuex";
export default {
  name: "RealTab",
  props: ["loading"],
  computed: {
    ...mapGetters(["realTimeList"]),
  },
  watch: {
    realTimeList(val) {
      // 处理数据
      let realTimeData = val && hasObjArr(val) ? val : [];
      // 删除不是实时数据的值
      let changeRealTimeData = [];
      hasObjArr(realTimeData)
        ? realTimeData.map((v) => {
            v.sid.includes("/realtime") ? changeRealTimeData.push(v) : null;
          })
        : null;
      // 本地存储的数据
      let sessionRealTimeData = sessionStorage.getItem("realTimeData")
        ? JSON.parse(sessionStorage.getItem("realTimeData"))
        : null;
      // 复制数据
      let deviceDataClone = sessionRealTimeData
        ? sessionRealTimeData
        : { ...this.deviceData };
      // 按照时间处理
      let fitTimeData = [];
      hasObjArr(changeRealTimeData)
        ? changeRealTimeData.map((v) => {
            fitTimeData.push({
              name: v.pid,
              value: v.v,
              time: v.s * 1000 + v.ms,
            });
          })
        : null;
      let sortTimeData = hasObjArr(fitTimeData)
        ? fitTimeData.sort((a, b) => {
            return compare(b, a, "time", "data");
          })
        : [];
      // 删除多余数据
      let changeData = {};
      hasObjArr(sortTimeData)
        ? sortTimeData.map((val) => {
            changeData.hasOwnProperty(val.name)
              ? null
              : (changeData[val.name] = val.value);
          })
        : null;
      //放入数据
      for (let i in changeData) {
        deviceDataClone[i] = changeData[i];
      }
      // 本地存储
      sessionStorage.setItem("realTimeData", changeJSONStr(deviceDataClone));
      this.deviceData = deviceDataClone;
    },
  },
  data() {
    return {
      deviceIconList: ["1", "2", "3", "4"],
      deviceContentList: [
        {
          name: "工作状态",
          type: "workstate",
        },
        {
          name: "吨位 (T)",
          type: "capacity",
        },
        {
          name: "同步距离 (mm)",
          type: "relative",
        },
        {
          name: "绝对距离 (mm)",
          type: "absolute",
        },
      ],
      deviceData: {
        workstate1: "0",
        workstate2: "0",
        workstate3: "0",
        workstate4: "0",
        capacity1: "0",
        capacity2: "0",
        capacity3: "0",
        capacity4: "0",
        relative1: "0",
        relative2: "0",
        relative3: "0",
        relative4: "0",
        absolute1: "0",
        absolute2: "0",
        absolute3: "0",
        absolute4: "0",
      },
    };
  },
  created() {},
  methods: {},
};
</script>  
<style lang="scss">
@import "./realtime.scss";
</style>

<template>
  <div class="versionBox">
    <TitleBox :titleText="title" :isBack="true" />
    <div class="contentBox">
      <div class="imgBox">
        <img :src="logo" alt="" />
      </div>
      <p>{{ siteTitle }}</p>
      <p>{{ version }}</p>
    </div>
    <Nav />
  </div>
</template>
<script>
import Nav from "../../components/Common/Nav.vue";
import TitleBox from "../../components/Common/Subject.vue";
import logoImg from "@/assets/images/logo.png";
import { isGetToken } from "@/utils/index";

export default {
  name: "Version",
  components: {
    Nav,
    TitleBox,
  },
  data() {
    return {
      title: "版本信息",
      siteTitle: process.env.VUE_APP_TITLE || "PLC同步顶升实时监测系统",
      logo: logoImg,
      version: "v1.0",
    };
  },
  created() {
    // 是否有token
    isGetToken(this);
  },
  methods: {},
};
</script>
<style lang="scss">
@import "./user.scss";
</style>

// 判断权限
export function isPermission() {
    // 登录用户
    let whoamiData = sessionStorage.getItem("whoami")
        ? JSON.parse(sessionStorage.getItem("whoami"))
        : null;
    let is_admin = whoamiData && whoamiData.is_admin ? true : false
    return is_admin
}

// 转换为json格式字符串
export function changeJSONStr(obj) {
    let result = '';
    result = JSON.stringify(obj)
    return result
}

// 判断是否为空对象或空数组
export function hasObjArr(obj) {
    return obj && Object.keys(obj).length > 0
}

// 返回是否为null或undefined
export const isNullOrUndefined = (value) => {
    return value !== null && value !== undefined ? true : false
}

// 数组筛选(完全相等)
export function filterArr(arr, compared, params) {
    let objArr = []
    arr && isArray(arr) && params ? objArr = arr.filter((item) => item[params] == compared) : null
    return objArr
}

// 数组筛选(模糊匹配)
export function filterArr_blurry(arr, compared, params) {
    let objArr = [];
    if (arr && arr instanceof Array) {
        arr.map(v => {
            String(v[params]).toUpperCase().includes(String(compared).toUpperCase()) ?
                objArr.push(v) : null
        })
    }
    return objArr
}

// 获取token
export function getToken() {
    return sessionStorage.getItem("token");
}
// 判断是否有token
export function isGetToken(that) {
    let token = sessionStorage.getItem("token");
    token ? null : that.$router.push("/login");
}

// 对比数据
export function compare(obj1, obj2, params, type) {
    if (type === 'time') {
        let val1 = moment(obj1[params]).format('YYYYMMDDHHmmss');
        let val2 = moment(obj2[params]).format('YYYYMMDDHHmmss');
        if (val1 < val2) {
            return -1;
        } else if (val1 > val2) {
            return 1;
        } else {
            return 0;
        }
    } else if (type === 'data') {
        let val1 = Number(obj1[params]);
        let val2 = Number(obj2[params]);
        if (val1 < val2) {
            return -1;
        } else if (val1 > val2) {
            return 1;
        } else {
            return 0;
        }
    }
}

// 获取指标数值
export function getAlarmTypeValue(type) {
    let result = null
    switch (type) {
        case '泵电机故障':
            result = 'I021'
            break
        case 'CB1 急停':
            result = 'I002'
            break
        case 'CB2 急停':
            result = 'I013'
            break
        case '1#位移传感器故障':
            result = 'M207'
            break
        case '1#压力传感器故障':
            result = 'M218'
            break
        case '1#载荷超限报警':
            result = 'M219'
            break
        case '1#同步工作故障':
            result = 'M0410'
            break
        case '2#位移传感器故障':
            result = 'M2211'
            break
        case '2#压力传感器故障':
            result = 'M2312'
            break
        case '2#载荷超限报警':
            result = 'M2313'
            break
        case '2#同步工作故障':
            result = 'M0514'
            break
        case '3#位移传感器故障':
            result = 'M2415'
            break
        case '3#压力传感器故障':
            result = 'M2516'
            break
        case '3#载荷超限报警':
            result = 'M2517'
            break
        case '3#同步工作故障':
            result = 'M0618'
            break
        case '4#位移传感器故障':
            result = 'M2619'
            break
        case '4#压力传感器故障':
            result = 'M2720'
            break
        case '4#载荷超限报警':
            result = 'M2721'
            break
        case '4#同步工作故障':
            result = 'M0722'
            break
    }
    return result
}
// 获取指标Name
export function getAlarmTypeName(type) {
    let result = null
    switch (type) {
        case 'I021':
            result = '泵电机故障'
            break
        case 'I002':
            result = 'CB1 急停'
            break
        case 'I013':
            result = 'CB2 急停'
            break
        case 'M207':
            result = '1#位移传感器故障'
            break
        case 'M218':
            result = '1#压力传感器故障'
            break
        case 'M219':
            result = '1#载荷超限报警'
            break
        case 'M0410':
            result = '1#同步工作故障'
            break
        case 'M2211':
            result = '2#位移传感器故障'
            break
        case 'M2312':
            result = '2#压力传感器故障'
            break
        case 'M2313':
            result = '2#载荷超限报警'
            break
        case 'M0514':
            result = '2#同步工作故障'
            break
        case 'M2415':
            result = '3#位移传感器故障'
            break
        case 'M2516':
            result = '3#压力传感器故障'
            break
        case 'M2517':
            result = '3#载荷超限报警'
            break
        case 'M0618':
            result = '3#同步工作故障'
            break
        case 'M2619':
            result = '4#位移传感器故障'
            break
        case 'M2720':
            result = '4#压力传感器故障'
            break
        case 'M2721':
            result = '4#载荷超限报警'
            break
        case 'M0722':
            result = '4#同步工作故障'
            break
    }
    return result
}